export { default as LsAnnotation } from './annotation.svg';
export { default as LsBanSquare } from './ban.svg';
export { default as LsCheckAlt } from "./check_alt.svg";
export { default as LsCrossAlt } from "./cross_alt.svg";
export { default as LsSparkSquare } from './spark.svg';
export { default as LsThumbsDown } from './thumbs_down.svg';
export { default as LsThumbsUp } from './thumbs_up.svg';
export { default as LsStarSquare } from  './star_square.svg';
export { default as LSPlus } from './plus.svg';
export { default as LsRefresh } from "./refresh.svg";
export { default as LsGear } from "./gear.svg";
export { default as CommentCheck } from "./comment_check.svg";
export { default as CommentRed } from "./comment_red.svg";